<template>
  <Dialog
    :visible="walletTab !== false"
    @update:visible="toggle"
    closable
    modal
    :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    contentClass="min-h-[300px]"
  >
    <template #header>
      <div
        v-if="options.length"
        class="flex md:flex-row flex-col items-center justify-center w-full"
      >
        <SelectButton
          v-model="selectedTab"
          :options="options"
          aria-labelledby="custom"
        >
          <template #option="slotProps">
            <div class="flex items-center justify-center">
              <div class="md:flex items-center justify-center hidden mr-2">
                <Icon :icon="slotProps.option.icon" class="text-2xl"></Icon>
              </div>
              <span class="md:text-base text-xs">{{
                slotProps.option.label
              }}</span>
            </div>
          </template>
        </SelectButton>
        <a
          class="md:hidden flex mt-2 underline decoration-1"
          target="_blank"
          href="https://changelly.com/buy-crypto"
          >{{ $t('wallet-modal.buy_crypto') }}</a
        >
      </div>
    </template>

    <div
      v-if="
        !user.total_tokens &&
        /^\/games\/.*$/.test(route.path) &&
        selectedTab.value == 'deposit'
      "
      class="text-xl mb-4 mt-4"
    >
      <Message>{{ $t('app.not-enough-tokens-body') }} </Message>
    </div>

    <WalletDeposit v-if="selectedTab.value == 'deposit'" />
    <WalletRedeemTokens v-if="selectedTab.value == 'exchange'" />
    <WalletWithdrawNow v-if="selectedTab.value == 'withdraw'" />
  </Dialog>
</template>

<script setup>
import { useUserStore } from '@/stores/user';
import SelectButton from 'primevue/selectbutton';
import Message from 'primevue/message';
const { t } = useI18n();


const user = useUserStore();
const screenInfo = useScreenSize();
const walletTab = computed(() => user.walletTab);
const isMobile = computed(() => screenInfo.isMobile.value);
const route = useRoute();

const options = computed(() => {
  const opts = [
    {
      label: t('wallet-modal.deposit'),
      value: 'deposit',
      icon: 'fa-solid fa-money-check-dollar',
    },
    {
      label: t('wallet-modal.withdraw'),
      value: 'withdraw',
      icon: 'fa-regular fa-credit-card',
    },
    {
      label: t('wallet-modal.exchange'),
      value: 'exchange',
      icon: 'fa-solid fa-rotate',
    },
    {
      label: t('wallet-modal.buy_crypto'),
      value: 'buy_crypto',
      icon: 'fa-solid fa-coins',
    },
  ];

  if (isMobile.value) {
    opts.pop();
  } else {
    opts.concat({
      label: t('wallet-modal.buy_crypto'),
      value: 'buy_crypto',
      icon: 'fa-solid fa-coins',
    });
  }

  return opts;
});

const selectedTab = ref(null);

const findObjectByValue = (valueToFind) => {
  return options.value.find((option) => option.value === valueToFind);
};

const toggle = () => {
  user.toggleWalletTab(false);
};

watch(selectedTab, (newValue, oldValue) => {
  if (newValue.value === 'buy_crypto') {
    selectedTab.value = oldValue;
    window.open('https://changelly.com/buy-crypto', '_blank');
  }
});

watch(walletTab, () => {
  selectedTab.value = findObjectByValue(
    walletTab.value ? walletTab.value : 'deposit'
  );
});
</script>
