<template>
  <div class="mb-5 md:mb-15">
    <h1 class="text-center">{{ $t('exchange.title') }}</h1>

    <p class="md:text-lg mt-8">
      {{ $t('exchange.subtitle') }}
    </p>

    <div class="w-full">
      <div class="mt-10 flex">
        <div class="me-4">
          <label class="text-sm text-white block mb-2">{{
            $t('exchange.you_send')
          }}</label>
          <Dropdown
            v-model="fromCurrency"
            :options="currencies"
            optionLabel="name"
            optionValue="value"
            placeholder="Currency"
            class="w-30"
          />
        </div>
        <div class="w-full">
          <label class="block mb-2 flex">
            <span>
              {{ $t('exchange.your_balance') }}
              {{
                fromCurrency === 'Tokens'
                  ? balance.totalTokens
                  : balance.totalCoins
              }}
            </span>
          </label>
          <InputGroup class="w-full">
            <InputText v-model="from" :placeholder="$t('app.amount')" />
            <Button severity="secondary" label="MAX" @click="setMaxAmount" />
          </InputGroup>
        </div>
      </div>

      <div class="flex justify-center">
        <div class="flex items-center mt-10">
          <Button @click="handleInterchange" rounded severity="secondary"
            ><Icon icon="fa-solid fa-retweet"
          /></Button>
        </div>
      </div>

      <div class="mt-10 flex">
        <div class="me-4">
          <label class="text-sm text-white block mb-2">{{
            $t('exchange.you_get')
          }}</label>
          <Dropdown
            v-model="toCurrency"
            :options="currencies"
            optionLabel="name"
            optionValue="value"
            placeholder="Currency"
            class="w-30"
          />
        </div>

        <div class="w-full">
          <label class="block mb-2">
            1 {{ fromCurrency }} ≈ {{ oneExchange }}
            {{ toCurrency }}
          </label>
          <InputText
            v-model="to"
            class="w-full"
            :placeholder="$t('app.amount')"
            disabled
          />

          <vue-hcaptcha
            v-if="website.sitekey"
            @verify="redeem"
            :sitekey="website.sitekey"
            ref="myCaptcha"
            size="invisible"
          ></vue-hcaptcha>
        </div>
      </div>

      <div class="w-full flex justify-center">
        <Button
          @click="handleRedeem"
          class="mt-8 w-1/2 flex justify-center"
          :disabled="loading"
        >
          {{ $t('exchange.confirm') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useWebsiteInfoStore } from '@/stores/website';
import { useUserStore } from '@/stores/user';
import { useToast } from 'primevue/usetoast';
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';

const websiteInfoStore = useWebsiteInfoStore();
const levelStore = useLevelStore();

const website = computed(() => websiteInfoStore.website);
const api = useApi();
const userStore = useUserStore();
const toast = useToast();
const { t } = useI18n();

const balance = ref({
  totalTokens: 0,
  totalUsdTokens: 0,
  totalCoins: 0,
});
const loading = ref(true);
const from = ref(0);
const to = ref(0);
const currencies = ref([]);
const fromCurrency = ref(null);
const toCurrency = ref(null);
const oneExchange = ref(null);
const myCaptcha = ref(null);
const hcaptcha = ref('');

watch(fromCurrency, (newValue) => {
  if (newValue === 'Tokens') {
    toCurrency.value = website.value.currency;
  } else {
    toCurrency.value = 'Tokens';
  }
  oneExchange.value = convertCurrency(1, fromCurrency.value);
  updateTo(from.value);
});

watch(from, (newValue) => {
  updateTo(newValue);
});

onBeforeMount(async () => {
  await levelStore.fetchLevels();
});

onMounted(async () => {
  const response = await api.execute('/api/tokens-balance');
  balance.value.totalTokens = response.total_tokens;
  balance.value.totalUsdTokens = response.usd_tokens;
  balance.value.totalCoins = response.total_coins;

  currencies.value.push(
    { name: website.value.currency, value: website.value.currency },
    { name: 'Tokens', value: 'Tokens' }
  );

  fromCurrency.value = 'Tokens';
  loading.value = false;
});

const handleInterchange = () => {
  let temp;
  temp = fromCurrency.value;
  fromCurrency.value = toCurrency.value;
  toCurrency.value = temp;
};

const updateTo = (num) => {
  to.value = convertCurrency(parseFloat(num), fromCurrency.value);
};

const convertCurrency = (amount, fromCurrency) => {
  const tokensPerDollar = 500; // 500 tokens is 1 dollar

  let result = 0;

  if (fromCurrency === 'Tokens') {
    const dollars = amount / tokensPerDollar;
    result = dollars / website.value.exchange_rate;
  } else {
    const dollars = amount * website.value.exchange_rate;
    result = dollars * tokensPerDollar;
  }

  if (isNaN(result)) {
    result = 0;
  }

  return formatAmount(result);
};

const handleRedeem = () => {
  myCaptcha.value.execute();
};

const redeem = async (token) => {
  hcaptcha.value = token;
  const amount = from.value;
  const response = await api.execute('/api/exchange', 'POST', {
    amount: amount,
    currency: fromCurrency.value,
    hcaptcha: hcaptcha.value,
  });

  balance.value.totalTokens = response.balance_tokens;
  balance.value.totalUsdTokens = response.usd_tokens;
  balance.value.totalCoins = response.balance_total_coins;
  userStore.updateUser({
    total_tokens: response.total_tokens,
    total_coins: response.balance_total_coins,
  });

  toast.add({
    severity: 'success',
    summary: t('exchange.toast_title'),
    detail: t('exchange.toast_description', {
      from: `${from.value} ${fromCurrency.value}`,
      to: `${to.value} ${toCurrency.value}`,
    }),
    life: 2000,
  });

  from.value = 0;
  to.value = 0;
};

const setMaxAmount = () => {
  from.value =
    fromCurrency.value === 'Tokens'
      ? balance.value.totalTokens
      : balance.value.totalCoins;
};
</script>
