<template>
  <div v-if="!loading && userLevel">
    <div class="flex" v-if="props.type === 'extended'">
      <div>
        <CommonBadge :badge="userLevel.badge" class="mt-3 me-3" size="sm" />
      </div>
      <div class="md:w-1/2">
        <p class="mt-2 text-lg font-semibold">
          {{ userLevel.tier }}
        </p>
        <ProgressBar :value="parseFloat(percentToNextLevel)" class="h-6">
        </ProgressBar>
        <div class="flex justify-between">
          <p class="text-gray-400 mt-2 text-xs md:text-sm">
            {{
              formatBigInteger(
                user && user.user_level?.earned ? user.user_level?.earned : 0
              )
            }}
            /
            {{ formatBigInteger(userLevel.next_level_earning_requirement) }}
            Tokens
          </p>
          <p class="text-gray-400 mt-2 text-end text-xs md:text-sm">
            {{ formatBigInteger(wagerToNextLevel) }}
            {{
              $t('vip-club.tokens_until_level', {
                level: userLevel.next_level_tier,
              })
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="bg-[--surface-card] flex p-4 rounded-xl w-[300px]" v-else>
      <div
        class="w-1/4 text-center flex flex-col justify-center cursor-pointer"
        @click="() => navigateTo('/vip-club')"
      >
        <CommonBadge :badge="userLevel.badge" size="sm" />
        <p class="font-semibold overflow-ellipsis">
          {{ userLevel.tier }}
        </p>
      </div>
      <div class="w-3/4 ml-4">
        <NuxtLink to="/vip-club" class="font-semibold text-gray-400">{{
          $t('vip-club.vip_level_progress')
        }}</NuxtLink>
        <p class="mt-2 text-xs md:text-sm">{{ percentToNextLevel }} %</p>
        <ProgressBar
          :value="parseFloat(percentToNextLevel)"
          :showValue="false"
          class="h-3"
        ></ProgressBar>
        <p class="text-xs md:text-sm text-gray-400 mt-2">
          {{ formatBigInteger(wagerToNextLevel) }}
          {{
            $t('vip-club.tokens_until_level', {
              level: userLevel.next_level_tier,
            })
          }}
        </p>
      </div>
    </div>
  </div>
  <div v-else class="flex p-3 space-x-2 min-w-[300px]">
    <Skeleton height="6rem" class="basis-1/3" />
    <Skeleton height="6rem" class="basis-2/3" />
  </div>
</template>

<script setup>
const props = defineProps({
  type: {
    type: String,
    default: () => 'card',
  },
});

const levelStore = useLevelStore();
const userStore = useUserStore();

const levels = computed(() => levelStore.levels);
const loading = computed(() => levelStore.loading);

const user = computed(() => userStore.user);

const userLevel = computed(() =>
  levels.value.find((v) =>
    user.value.user_level
      ? v.id === user.value.user_level.level_id
      : v.earning_requirement === 0
  )
);

const percentToNextLevel = computed(() => {
  const currentLevelRequirement = userLevel.value.earning_requirement;

  const currentWagered = user.value.user_level
    ? user.value.user_level.earned - currentLevelRequirement
    : 0;

  return (
    (currentWagered * 100) /
    (userLevel.value.next_level_earning_requirement - currentLevelRequirement)
  ).toFixed(2);
});

const wagerToNextLevel = computed(() =>
  user.value.user_level
    ? userLevel.value.next_level_earning_requirement -
      user.value.user_level.earned
    : userLevel.value.next_level_earning_requirement
);

onBeforeMount(async () => {
  await levelStore.fetchLevels();
});
</script>
